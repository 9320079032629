















































































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import CustomAutocomplete from '@/vue-app/components/CustomAutocomplete.vue';
import ContractSavingsAdditionalInformationFinancialInformationViewModel
  from '@/vue-app/view-models/components/contract-savings/additional-information/contract-savings-additional-information-financial-information-view-model';

@Component({
  name: 'ContractSavingsAdditionalInformationFinancialInformation',
  components: {
    CustomAutocomplete,
    ContractSavingsActions: () => import('@/vue-app/components/contract-savings/ContractSavingsActions.vue'),
  },
})
export default class ContractSavingsAdditionalInformationFinancialInformation extends Vue {
  financial_information_view_model = Vue.observable(
    new ContractSavingsAdditionalInformationFinancialInformationViewModel(),
  );

  @Watch('financial_information_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  @Watch('financial_information_view_model.confirm_information')
  handleConfirmInformationChange(new_confirmation: boolean) {
    this.financial_information_view_model.handleAgreementChange(
      'confirm_bank_information_kuspit',
      new_confirmation,
    );
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async nextStep() {
    const save_step = await this.financial_information_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }

  async created() {
    this.financial_information_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.financial_information_view_model.initialize();

    this.$emit('loadingInfo', this.financial_information_view_model.is_loading);
  }
}

